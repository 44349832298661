import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { WishlistProductItemFragmentDoc } from './WishlistProductItemFragment.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetActiveWishlistQueryVariables = Types.Exact<{
  searchCriteria?: Types.InputMaybe<Types.WishlistSearchCriteriaInput>;
  context: Types.RequestContextInput;
}>;

export type GetActiveWishlistQuery = {
  __typename?: 'Query';
  wishlist: {
    __typename?: 'WishlistContext';
    active: {
      __typename?: 'WishlistActiveComponent';
      status: Types.Status;
      message?: string | null;
      items: Array<{
        __typename?: 'WishlistProductItem';
        type: Types.WishlistProductItemType;
        source?: string | null;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        offer: {
          __typename?: 'WishlistOffer';
          id: string;
          duration: number;
          returnDate: string;
          travelType: Types.TravelType;
          departureDate: string;
          transferIncluded: boolean;
          waitingScreenImage?: string | null;
          provider?: string | null;
          rooms: Array<{
            __typename?: 'WishlistOfferRoom';
            adults: number;
            children: number;
            boardCode: string;
            boardDescription: string;
            roomCode: string;
            roomDescription: string;
          }>;
          inboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          outboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          totalPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          pricePerPerson: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          wishlistItem?: {
            __typename?: 'WishlistItem';
            id: string;
            inWishlist: boolean;
          } | null;
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
        };
        product: {
          __typename?: 'WishlistProduct';
          id: string;
          giataId: number;
          name: string;
          zoom: number;
          taRating?: number | null;
          hpRating: number;
          taReviews?: number | null;
          productClass: Types.ProductClass;
          coordinates: {
            __typename?: 'GeoCoordinates';
            latitude: number;
            longitude: number;
          };
          breadcrumbs: Array<{
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          }>;
          includedInPrice?: {
            __typename?: 'TextComponent';
            title: string;
            text: string;
            bottomPadding?: string | null;
            topPadding?: string | null;
          } | null;
          featureSummary: Array<{
            __typename?: 'ProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'ProductFeature';
              id: string;
              name: string;
              enabled?: boolean | null;
            }>;
          }>;
          featureGroups: Array<{
            __typename?: 'ProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'ProductFeature';
              id: string;
              name: string;
              enabled?: boolean | null;
            }>;
          }>;
          image: {
            __typename?: 'Image';
            alt: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        };
        changedOffer?: {
          __typename?: 'WishlistOffer';
          id: string;
          duration: number;
          returnDate: string;
          travelType: Types.TravelType;
          departureDate: string;
          transferIncluded: boolean;
          waitingScreenImage?: string | null;
          provider?: string | null;
          rooms: Array<{
            __typename?: 'WishlistOfferRoom';
            adults: number;
            children: number;
            boardCode: string;
            boardDescription: string;
            roomCode: string;
            roomDescription: string;
          }>;
          inboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          outboundFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
          totalPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          pricePerPerson: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          wishlistItem?: {
            __typename?: 'WishlistItem';
            id: string;
            inWishlist: boolean;
          } | null;
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
        } | null;
      }>;
    };
  };
};

export const GetActiveWishlistDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActiveWishlist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchCriteria' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WishlistSearchCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wishlist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'active' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchCriteria' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'wishlistProductItem',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...WishlistProductItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetActiveWishlistQuery__
 *
 * To run a query within a React component, call `useGetActiveWishlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveWishlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveWishlistQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetActiveWishlistQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetActiveWishlistQuery,
    GetActiveWishlistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetActiveWishlistQuery,
    GetActiveWishlistQueryVariables
  >(GetActiveWishlistDocument, options);
}
export function useGetActiveWishlistLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetActiveWishlistQuery,
    GetActiveWishlistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetActiveWishlistQuery,
    GetActiveWishlistQueryVariables
  >(GetActiveWishlistDocument, options);
}
export type GetActiveWishlistQueryHookResult = ReturnType<
  typeof useGetActiveWishlistQuery
>;
export type GetActiveWishlistLazyQueryHookResult = ReturnType<
  typeof useGetActiveWishlistLazyQuery
>;
export type GetActiveWishlistQueryResult = Apollo.QueryResult<
  GetActiveWishlistQuery,
  GetActiveWishlistQueryVariables
>;
