import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { TextComponentFragmentDoc } from 'graphql/text/TextComponent.generated';
import { GroupOfProductFeatureItemsFragmentDoc } from 'graphql/productFeature/GroupOfProductFeatureItems.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
export type WishlistProductFragment = {
  __typename?: 'WishlistProduct';
  id: string;
  giataId: number;
  name: string;
  zoom: number;
  taRating?: number | null;
  hpRating: number;
  taReviews?: number | null;
  productClass: Types.ProductClass;
  coordinates: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  };
  breadcrumbs: Array<{
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  }>;
  includedInPrice?: {
    __typename?: 'TextComponent';
    title: string;
    text: string;
    bottomPadding?: string | null;
    topPadding?: string | null;
  } | null;
  featureSummary: Array<{
    __typename?: 'ProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'ProductFeature';
      id: string;
      name: string;
      enabled?: boolean | null;
    }>;
  }>;
  featureGroups: Array<{
    __typename?: 'ProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'ProductFeature';
      id: string;
      name: string;
      enabled?: boolean | null;
    }>;
  }>;
  image: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export const WishlistProductFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'wishlistProduct' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WishlistProduct' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giataId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zoom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hpRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taReviews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productClass' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breadcrumbs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'includedInPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'textComponent' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupOfProductFeatureItems' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupOfProductFeatureItems' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '728' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '294' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '147' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '298' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '200' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '596' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '400' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextComponentFragmentDoc.definitions,
    ...GroupOfProductFeatureItemsFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
